/* App.css */
:root {
  --text-color: #e9e9e9;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
}

.ant-btn {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.ant-btn-primary {
  border-radius: 4px !important;
}

.ant-btn-primary:hover {
  /* color: var(--text-color) !important; */
  color: white !important;
  background-color: var(--text-color) !important;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 0.1),
    0px -0px 8px 0px rgb(0 0 0 / 0.1) !important;
}

.ant-btn-primary:disabled {
  color: white !important;
  opacity: 0.5;
  background-color: var(--text-color) !important;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 0.1),
    0px -0px 8px 0px rgb(0 0 0 / 0.1) !important;
}

.ant-input:hover {
  border-color: var(--text-color);
}

.ant-input:focus {
  border-color: var(--text-color);
}

.ant-input-password:hover {
  border-color: var(--text-color) !important;
}

.ant-input-password:focus {
  border-color: var(--text-color) !important;
}

.ant-select-selector:hover {
  border-color: var(--text-color) !important;
}

.ant-select-selector:focus {
  border-color: var(--text-color) !important;
}

.ant-select-item-option:hover {
  color: var(--text-color) !important;
}

.ant-checkbox-input:focus {
  border-color: var(--text-color) !important;
}

.ant-checkbox-input:hover {
  border-color: var(--text-color) !important;
}
.ant-switch-disabled {
  cursor: not-allowed;
  background-color: #f5f5f5 !important; /* Same background as disabled input */
  border-color: #d9d9d9 !important; /* Remove red border on hover */
}

.ant-switch-disabled:hover {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
:where(.css-dev-only-do-not-override-p8b6i).ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice {
  width: auto !important; /* Allows width to adjust based on content */
  max-width: 100%; /* Ensures it doesn't overflow the screen */
  height: auto !important; /* Adjusts height based on content */
  white-space: nowrap; /* Prevents text wrapping and keeps content in a single line */
  overflow: visible; /* Ensures content isn't clipped */
}
:where(
    .css-dev-only-do-not-override-p8b6i
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  font-family: "Poppins" !important;
  color: black !important;
}
.ant-input {
  color: #1f2937 !important;
  padding: 4px 12px !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px !important;
  outline: none !important;
  border-color: var(
    --text-color-op-40
  ) !important; /* Example: Green border color */
}

.ant-input:focus {
  border-color: var(--text-color);
  outline: 0 !important;
  /* -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, .2); */
  box-shadow: none !important;
}
.ant-picker {
  color: #1f2937 !important;
  padding: 4px 12px !important;
  font-size: 14px !important;
  font-weight: 400;
  outline: none !important;
  border-color: var(
    --text-color-op-40
  ) !important; /* Example: Green border color */
}
.ant-picker-input input {
  line-height: 20px !important;
}
/* When focused */
.ant-picker-focused {
  border-color: var(--text-color);
  outline: 0 !important;
  /* -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, .2); */
  box-shadow: none !important;
}

.css-dev-only-do-not-override-p8b6i .ant-picker-outlined {
  border-color: var(
    --text-color-op-40
  ) !important; /* Example: Green border color */
}

.ant-select-selector:hover {
  border: 1px solid var(--text-color) !important;
}
.ant-select-selector {
  border-color: var(
    --text-color-op-40
  ) !important; /* Example: Green border color */
}
.ant-select-disabled .ant-select-selector {
  background-color: #fafbfc !important; /* Even lighter gray background */
  cursor: not-allowed; /* Optional: show a not-allowed cursor */
}
.ant-select-focused .ant-select-selector {
  border-color: var(--text-color-op-40) !important;
}

.ant-select-selector .ant-select-selection-placeholder {
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  color: #9ca3af !important;
}

/* Hover effect on the DatePicker input */
.ant-picker:hover {
  border: 1px solid var(--text-color) !important;
}

/* Focused state of the DatePicker input */
.ant-picker-focused {
  border-color: var(--text-color-op-40) !important;
}

/* Placeholder text styling in DatePicker */
.ant-picker-input > input::placeholder {
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  color: #9ca3af !important;
}

/* Background color change on focus */

.ant-input::placeholder {
  /* Your desired styles here */
  color: #9ca3af;
  font-weight: 400;
  line-height: 20px;
  font-family: "Poppins", sans-serif !important;
}

.ant-btn-default:disabled {
  cursor: auto !important;
  /* background: #2271b8; */
  opacity: 0.5;
  /* color: #fff; */
}

:where(.css-dev-only-do-not-override-p8b6i).ant-input-outlined:hover {
  border-color: var(--text-color);
  padding: 4px 12px !important;
}

/* For text area */
ant-input-affix-wrapper,
.ant-input-affix-wrapper input {
  border-radius: 4px !important;
}

/* For select inputs */
.ant-select-selector {
  border-radius: 4px !important;
}
.ant-input {
  border-radius: 4px !important;
}
.ant-select-arrow {
  color: #9e9e9e !important;
  transform: rotate(-90deg) !important;
  transition: color 0.3s ease;
  font-size: 12px !important; /* Rotate to point right */
}

/* Downward arrow when the dropdown is open */
.ant-select-open .ant-select-arrow {
  transform: rotate(0deg) !important; /* Reset rotation to point down */
}
.ant-btn:hover {
  background-color: var(
    --text-color
  ) !important; /* Replace with your primary color */
  color: white !important; /* Ensure text is white on hover */
  box-shadow: none !important;
  border: none !important ; /* Ensure no shadow on hover */
}

.my-form
  .ant-form-item
  .ant-form-item-row
  .ant-col
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-disabled {
  cursor: auto !important;
  color: rgb(0, 0, 0);
}

.my-form
  .ant-form-item
  .ant-form-item-row
  .ant-col
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-number-input {
  color: rgb(95, 94, 94);
}

.my-form
  .ant-form-item
  .ant-form-item-row
  .ant-col
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-number-input
  input[disabled] {
  background-color: red !important;
}

.ant-input-number-outlined.ant-input-number-disabled input[disabled] {
  cursor: auto !important;
  background-color: #fafbfc !important;
}

.ant-select-disabled:where(.css-dev-only-do-not-override-p8b6i).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  cursor: auto !important;
  background: #fafbfc !important;
}

.ant-select-disabled:where(.css-dev-only-do-not-override-p8b6i).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  cursor: auto !important;
}

.ant-switch-disabled {
  cursor: auto !important;
}

/* .my-form .ant-form-item .ant-form-item-row .ant-form-item-label {

} */
.ant-form-item .ant-form-item-row .ant-form-item-label label {
  font-family: "Poppins" !important;
  color: #000000 !important;
  font-size: 14px;
  font-weight: 400;
  padding-left: 2px !important;
}
.ant-form-item .ant-form-item-label {
  margin-bottom: 0 !important;
  padding-bottom: 2px !important;
}
/* For text area */
.ant-input-affix-wrapper,
.ant-input-affix-wrapper input {
  border-radius: 4px !important;
  border-color: var(
    --text-color-op-40
  ) !important; /* Example: Green border color */
}

/* For select inputs */
.ant-select-selector {
  border-radius: 4px !important;
}

.ant-input {
  border-radius: 4px !important;
  border-color: var(
    --text-color-op-40
  ) !important; /* Example: Green border color */
}
.ant-input:disabled {
  background-color: #fafbfc !important; /* Light gray background for disabled input */
  pointer-events: auto !important;
  cursor: not-allowed; /* Optional: Indicate the input is disabled */
}

.ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  input {
  /* min-height: 30px !important; */
}

.ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-password {
  height: 30px !important;
}

.ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  #password {
  height: 100% !important;
  /* padding-bottom: 10px !important; */
  background-color: transparent !important;
}

.ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  input[password] {
  height: fit-content !important;
}

.ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select-selector {
  background: #fff;
}

.ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select-disabled
  .ant-select-selector {
  background: #fafbfc;
}

.ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select-selector
  .ant-select-selection-item {
  color: #374151;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  content: "*";
  position: absolute;
  right: 0;
}

.ant-checkbox-wrapper {
  cursor: default !important;
}

.ant-checkbox-wrapper-checked .ant-checkbox-disabled {
  background-color: var(--text-color) !important;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: #fff !important;
}

.ant-checkbox-disabled + span {
  color: rgb(0, 0, 0) !important;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-checkbox-disabled,
:where(.css-dev-only-do-not-override-p8b6i).ant-checkbox-disabled
  .ant-checkbox-input {
  cursor: auto !important;
  color: #fff !important;
}

.ant-switch.ant-switch-checked {
  background-color: var(--text-color) !important;
}
/* Default right arrow for the select field */
.ant-select-arrow {
  color: #9e9e9e !important;
  transform: rotate(-90deg) !important;
  transition: color 0.3s ease;
  font-size: 12px !important; /* Rotate to point right */
}

/* Downward arrow when the dropdown is open */
.ant-select-open .ant-select-arrow {
  transform: rotate(0deg) !important; /* Reset rotation to point down */
}
.ant-btn:hover {
  background-color: var(
    --text-color
  ) !important; /* Replace with your primary color */
  color: white !important; /* Ensure text is white on hover */
  box-shadow: none !important;
  border: none !important; /* Ensure no shadow on hover */
}

.ant-table-thead .ant-table-cell {
  background-color: var(
    --text-color-op-10
  ) !important; /* Correct variable usage */
  color: #000000 !important;
  padding: 11px 12px !important;
  text-align: center;
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Poppins" !important;
  border-radius: none !important;
  width: fit-content !important;
}

.ant-table-thead .ant-table-cell:hover {
  background-color: var(
    --text-color-op-20
  ) !important; /* Correct hover background */
}
.ant-table-thead .ant-table-cell:last-child {
  border-right: none !important;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: "";
  width: 0 !important;
  background-color: transparent !important;
  height: 0 !important;
}

/* .my-table .ant-table-thead > tr > th {
  background-color: #f1f1f1e7 !important;
} */
/* .my-table .ant-table-thead .ant-table-cell:first-child {
     border-start-start-radius: none !important;
 }

 .my-table .ant-table-thead {
     border-start-start-radius: none !important;
 } */

.ant-table-wrapper .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #ffffff; /* White for odd rows */
}

.ant-table-wrapper .ant-table-tbody > tr:nth-child(even) > td {
  background-color: #f5f5f5; /* bg-gray-200 for even rows */
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  font-size: 14px !important;
  color: #000000 !important;
  padding: 0px 0px !important;
  line-height: 32px !important;
  height: 38px !important; /* Added height to ensure consistent row height */
  font-weight: 400;
  font-family: "Poppins" !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td:last-child {
  padding: 0 !important;
}

/* .my-table .ant-table-wrapper .ant-table-tbody .ant-table-row .ant-table-cell {
     border-right: 1px solid #ddd !important;

 }

 .my-table .ant-table-wrapper .ant-table-tbody .ant-table-row .ant-table-cell:last-child {
     border-right: none !important;

 } */

/* .my-table .ant-table-thead {
     border: 1px solid red !important;
 } */

.ant-table-wrapper .ant-table-tbody .ant-table-row .ant-table-selection-column {
  width: 50px !important; /* Fixed width for checkbox column */
  padding: 0 !important; /* Removed padding to prevent height variation */
  text-align: center; /* Center align checkbox */
  height: 38px !important; /* Set height to 38px for the checkbox column */
}

.ant-table-content {
  overflow-y: hidden !important;

  overflow-x: auto;
  border: 1px solid #e9e9e9 !important; /* gray-100 in hex */
  border-radius: 4px !important;
  scrollbar-width: none;
}

.ant-table-content::-webkit-scrollbar {
  height: 4px !important;
  /* Decrease the height of the horizontal scrollbar */
}

/* .my-table .ant-table-thead>tr>th:first-child {
     border-top-left-radius: 0 !important;
     border-bottom-left-radius: 0 !important;
 }

 .my-table .ant-table-thead>tr>th:last-child {
     border-top-right-radius: 0 !important;
     border-bottom-left-radius: 0 !important;
 } */

.ant-table-wrapper .ant-table-column-sorters {
  display: flex;
  /* flex: auto; */
  align-items: center;
  justify-content: start !important;
}

.ant-table-wrapper .ant-table-column-title {
  flex: none !important;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #2271b8 !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #2271b8 !important;
}

.ant-table-cell-row-hover {
  background-color: #f3f4f6 !important;
  cursor: pointer;
}

.my-table
  .ant-table-tbody
  .ant-table-row
  .ant-table-selection-column
  .ant-checkbox-wrapper
  .ant-checkbox:hover {
  box-shadow: 0px 0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}

.ant-table-selection-column {
  padding: 10px 0px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #808080 !important;
  /* Your custom hover color */
}

@media (max-width: 1750px) {
  .my-table .ant-table-content {
    /* overflow-x: scroll;
         
        border: 1px solid #ddd !important;
        border-radius: 8px !important; */
    scrollbar-width: thin;
  }
}

/* @media (max-width: 1080px) {
     .my-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
         overflow-x: scroll !important;
     }
 }

 @media (max-width: 1180px) {
     .my-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
         overflow-x: scroll !important;
     }
 }

 @media (max-width: 1280px) {
     .my-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
         overflow-x: scroll !important;
     }
 }

 @media (max-width: 1350px) {
     .my-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
         overflow-x: scroll !important;
     }
 }

 @media (max-width: 1380px) {
     .my-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
         overflow-x: scroll !important;
     }
 }

 @media (max-width: 1417px) {
     .my-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
         overflow-x: scroll !important;
     }
 }

 @media (max-width: 1433px) {
     .my-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
         overflow-x: scroll !important;
     }
 } */
/* Pagination Styling */
.ant-pagination .ant-pagination-item-active {
  border-color: transparent !important; /* Remove border */
}

.ant-pagination .ant-pagination-item-active a {
  color: #2271b8 !important; /* Primary color */
}

.ant-pagination .ant-pagination-item {
  border-radius: 50% !important;
  margin-right: 8px;
}

.ant-pagination .ant-pagination-item:hover {
  background-color: #e5e7eb !important; /* bg-gray-200 */
}

/* Remove default white background on hover */
.ant-pagination .ant-pagination-item:hover {
  background-color: #e5e7eb !important; /* Override to bg-gray-200 */
}

.ant-pagination .ant-pagination-item {
  background-color: transparent !important; /* Ensure default state is transparent */
}
/* .ant-table-tbody {
  height: 150px;
  overflow-y: auto;
} */
:where(.css-dev-only-do-not-override-p8b6i).ant-select
  .ant-select-selection-item {
  font-weight: unset !important; /* Reset font-weight */
}
:where(.css-dev-only-do-not-override-p8b6i).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  display: none !important;
}
.my-table .ant-table-wrapper .ant-table-tbody > tr > td:last-child {
  /* right: 30px !important;
  width: 0 !important; */
}
.pagination.ant-btn-default {
  border-radius: 4px !important;
  border-color: rgb(237, 234, 234) !important;
  border-width: 2px !important;
}

:where(.css-dev-only-do-not-override-196tuf).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:hover {
  background-color: var(--text-color-op-10) !important; /* Use Tailwind color */
}
.ant-select-selection-item {
  font-family: "Poppins" !important;
  color: black !important;
  font-size: 14px !important;
}
