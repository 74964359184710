.ant-breadcrumb a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: transparent !important;
}

.ant-breadcrumb a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: var(--text-color);
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
  transform-origin: bottom right;
}

.ant-breadcrumb a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  color: var(--text-color);
}

.breadcrumb-link {
  color: black !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Poppins" !important;
  transition: color 0.3s ease-in-out;
}

.ant-breadcrumb-separator {
  color: #000000 !important;
  margin: 0px 16px !important;
}

.breadcrumb-link:hover {
  color: var(--text-color) !important;
}
