.ant-layout-header {
  background: #ffffff !important;
}

.ant-layout-sider-collapsed {
  padding: 2px 10px;
}

.ant-layout-sider {
  /* min-width: 208px !important; */
  font-weight: 500 !important;
  font-size: 14px !important;
}

.ant-layout-sider-children {
  padding-top: 0.125rem !important;
  margin: 0px 6px;
}

.ant-layout-sider-children ul {
  border-right: none !important;
}

.ant-layout-sider-children li {
  padding: 0px 10px !important;
  border-radius: 4px !important;
  /* border-bottom-width: 1px !important; */
  margin: 8px 2px !important;
}

.ant-menu-item {
  background: linear-gradient(to right, var(--text-color-op-10) 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}

.ant-menu-item:hover {
  /* color: white !important; */
  transform: translateX(6px);
  transition: 0.5s !important;
  background-color: none !important;
}

.ant-menu-item:not(.ant-menu-item-selected):hover {
  /* background-color: #c8e0ff !important; */
  background-position: left bottom;
}

.ant-menu-light .ant-menu-item-selected {
  transition: 0s !important;
  background: none !important;
  color: white !important;
  /* background-color: transparent !important; */
  background-color: var(--text-color) !important;
}

.ant-menu-item-selected:hover {
  transform: none !important;
}
