@tailwind base;
@tailwind components;
@tailwind utilities;

.reset-button {
  @apply w-[104px] h-[30px] rounded-[4px] mr-4 font-medium !bg-[#E9E9E9]  text-customColor border border-transparent hover:!bg-white hover:!text-customColor hover:!shadow-xl;
}
/* @keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
} */

@keyframes grow {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

.animate-grow {
  animation: grow 3s ease-in-out infinite;
}

.animate-grow-slow {
  animation: grow 5s ease-in-out infinite;
}

.ant-btn {
  font-size: 12px !important;
  box-shadow: none !important;
}

.ant-btn:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1),
    0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}

/* .ant-btn-default:hover {
  color: var(--text-color) !important;
} */

/* .ant-input {
  border: 1px solid #d1d5db;
} */

/* .ant-input::placeholder {
  color: #a7a7a7;
} */

.ant-input:hover {
  color: initial !important;
}
/* Force disabled input hover styles */
.ant-input[disabled] {
  pointer-events: none;
  cursor: default !important;
  color: rgb(0, 0, 0) !important;
}

.ant-btn:disabled {
  cursor: default !important;
}

/* Force disabled input hover styles */
.ant-switch[disabled] {
  pointer-events: none;
  cursor: default !important;
  color: rgb(0, 0, 0) !important;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.ant-checkbox-disabled + span {
  color: rgb(0, 0, 0) !important;
}

/* Force disabled input hover styles */
.ant-select[disabled] {
  pointer-events: none;
  cursor: default !important;
  color: rgb(0, 0, 0) !important;
}

.ant-input::placeholder {
  color: #a7a7a7;
  font-size: 0.875rem !important;
}

.ant-select-selection-placeholder {
  color: #a7a7a7 !important;
  font-size: 0.875rem !important;
}

.ant-picker-input input::placeholder {
  color: #a7a7a7 !important;
}

/* width */
::-webkit-scrollbar {
  height: 6px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b1b1b1;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
.ant-modal-content {
  padding: 10px 8px !important;
  background-color: #ffffff !important;
}

.ant-modal-body {
  background-color: white;
}
.ant-modal-content .ant-modal-footer {
  padding-right: 22px;
}

.ant-modal-content .ant-modal-footer .ant-btn-primary {
  background-color: var(--text-color) !important;
  border: 1px solid var(--text-color) !important;
}

.ant-modal-content .ant-modal-footer .ant-btn-default:hover {
  border: 1px solid var(--text-color) !important;
  /* color: var(--text-color) !important; */
}
.ant-modal-content {
  padding: 12px 10px !important;
}
.ant-modal-footer {
  padding: 0 !important;
  margin-top: 0 !important;
}
:where(.css-dev-only-do-not-override-196tuf).ant-form-item-vertical
  .ant-form-item-label,
:where(.css-dev-only-do-not-override-196tuf).ant-col-24.ant-form-item-label,
:where(.css-dev-only-do-not-override-196tuf).ant-col-xl-24.ant-form-item-label {
  padding-top: 6px !important; /* Add top padding */
  margin: 0;
  white-space: initial;
  text-align: start;
}
.ant-modal-close {
  margin-top: 18px !important;
  padding-right: 10px !important;
  color: black !important;
  background: none !important; /* Ensure no background */
}

/* Remove background when hovering */
.ant-modal-close:hover {
  background: none !important;
  color: red !important; /* Optional: Change color on hover */
}
