.login-bg {
  background-image:
        /* top, transparent red */ linear-gradient(
      rgba(31, 29, 29, 0.658),
      rgba(59, 58, 58, 0.541)
    ),
    /* your image */ url("../../assets/images/login.jpg");
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
}

/* .my-input .ant-form-item-row .ant-form-item-label label {
  color: #374151;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Poppins";
} */
my-input .ant-form-item-row .ant-form-item-label label {
  color: black;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Poppins";
}

.my-input .ant-form-item-row .ant-form-item-label {
  padding: 0 0 4px !important;
}

.my-input.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #374151 !important;
}
.ant-form-item {
  margin-bottom: 0 !important;
}
.ant-input-affix-wrapper:focus-within {
  background-color: white !important;
}

.my-input.ant-input-affix-wrapper {
  transition: background-color 0.3s ease;
}
.my-input.ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input-password{
  height: 39px !important;
}
/* Apply custom styles to the input field */
.ant-input {
  background-color: transparent !important; /* Remove default background color */
  font-family: "Poppins", sans-serif !important; /* Set font to Poppins */
}

/* Ensure the input field has no special background color when filled */
.ant-input:focus,
.ant-input:not(:placeholder-shown) {
  background-color: transparent !important;
}
