GlobalTable.css .ant-table-wrapper .ant-table-column-sorters {
  display: flex;
  flex-wrap: nowrap !important;
  align-items: center !important;
  justify-content: left !important;
  /* margin-left: 10px; */
}

.ant-table-column-title {
  flex: none !important;
}

.my-table .ant-table-thead .ant-table-cell {
  color: #000000e0 !important;
  padding: 10px 0 !important;
  /* text-align: center; */
  font-size: 14px !important;
  font-weight: 500;
}

.my-table thead > tr > th:first-child {
  border-top-left-radius: 4px !important;
}

.my-table thead > tr > th:last-child {
  border-top-right-radius: 4px !important;
}
.my-table .ant-table-wrapper .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #ffffff; /* White for odd rows */
}

.my-table .ant-table-wrapper .ant-table-tbody > tr:nth-child(even) > td {
  background-color: #f5f5f5; /* bg-gray-200 for even rows */
}
.action-buttons {
  display: flex;
  align-items: center;
}

.ant-table-content {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow-x: auto;
}

/* .my-table .ant-table-content::-webkit-scrollbar {
  height: 4px !important;
} */

.ant-table-row {
  height: 22px !important;
  cursor: pointer;
  flex-wrap: nowrap;
}

.my-table .ant-table-tbody > tr > td {
  text-overflow: ellipsis;
  /* overflow: hidden; */
  white-space: nowrap;
  font-size: 14px !important;
  color: #000000 !important;
  padding: 0px 0px !important;
  line-height: 32px !important;
  height: 38px !important; /* Added height to ensure consistent row height */
  font-weight: 400;
  font-family: "Poppins" !important;
}

/* globalTable.css */

.my-table .ant-table .ant-table-cell {
  padding: 10px 12px !important;
  line-height: 18px !important;
  /* height: 22px !important;  */
}

/* Add hover background color when hovering over table rows */
.ant-table-tbody > tr:hover .ant-table-cell {
  background-color: rgb(239, 240, 242) !important; /* Tailwind's gray-200 */
}

/* .ant-table-thead > tr > th {
  padding: 4px 8px !important; 
  line-height: 24px !important; 
} */

.ant-table-tbody > tr > td {
  font-size: 14px !important;
  color: #50565e !important;
  /* padding: 0px 24px; */
  /* line-height: 35px !important; */
  height: 43px !important;
  font-weight: 400;
}

.ant-pagination {
  font-size: 13px;
}

.ant-select-selector {
  font-size: 13px !important;
}

.my-table {
  max-width: 100%;
  /* overflow-x: auto; */
}

.my-table
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: "";
  width: 0 !important;
  background-color: transparent !important;
  height: 0 !important;
}

th {
  background-color: #f1f1f1e7 !important;
}

th:hover {
  background-color: rgb(229 231 235) !important;
}

.ant-table-tbody .ant-table-column-sort {
  background-color: transparent !important;
}

.ant-checkbox:hover {
  box-shadow: 0px 0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}

.ant-checkbox-wrapper .ant-checkbox-inner {
  border-color: #808080 !important;
}

@media (max-width: 1750px) {
  .my-table .ant-table-content {
    scrollbar-width: thin;
  }
}
:where(.css-dev-only-do-not-override-196tuf).ant-pagination
  .ant-pagination-item-active {
  font-weight: 600;
  background-color: transparent !important; /* Removes background color */
  border-color: transparent !important; /* Removes border color */
}
.pagination.ant-btn-default {
  border-radius: 4px !important;
  border-color: rgb(237, 234, 234) !important;
  background-color: #fefefe !important; /* Keep same as border */
  border-width: 2px !important;
  color: var(--text-color) !important; /* Default text color */
}

/* Hover effect */
.pagination.ant-btn-default:hover {
  color: var(--text-color) !important;  /* Change text color on hover */
}


