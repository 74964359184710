/* Force the color and opacity for disabled select dropdowns */
.ant-select-disabled .ant-select-selector .ant-select-selection-item {
  color: #000000 !important;
  opacity: 1 !important;
}

/* Optional: Adjust cursor and pointer events to keep a consistent look */
.ant-select-disabled .ant-select-selector {
  cursor: default !important;
  pointer-events: none;
}
